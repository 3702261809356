import cssVariable from './cssVariable';
import hashScroll from './hashScroll';
import imageLoaded from './imageLoaded';
import inview from './inview';
import toggleMenu from './toggleMenu';
import current from './current';
import topSlider from './slider';
import tab from './tab';

document.addEventListener('DOMContentLoaded', () => {
  cssVariable();
  hashScroll();
  imageLoaded();
  inview();
  toggleMenu();
  current();
  topSlider();
  tab();
});
