export default function tab() {
  const tabContainers = document.querySelectorAll('.js-tab');

  tabContainers.forEach((container) => {
    const tabLinks = container.querySelectorAll('.js-tab__link');
    const tabSelect = container.querySelector('.js-tab__select');
    const tabContents = container.querySelectorAll('.js-tab__body');

    function showTab(tabId) {
      // タブコンテンツの表示をリセット
      tabContents.forEach((content) => {
        content.classList.remove('is-show');
      });
      // 選択されたタブコンテンツを表示
      container.querySelector(`#${tabId}`).classList.add('is-show');

      // aタグのカレントクラスをリセット
      tabLinks.forEach((link) => {
        link.classList.remove('is-current');
      });
      // 選択されたaタグにカレントクラスを追加
      container.querySelector(`.js-tab__link[href="#${tabId}"]`).classList.add('is-current');

      // selectタグのカレントクラスをリセット
      tabSelect.value = tabId;
    }

    if (tabLinks.length > 0 && tabSelect && tabContents.length > 0) {
      // aタグのクリックイベントリスナー
      tabLinks.forEach((link) => {
        link.addEventListener('click', function handleClick(event) {
          event.preventDefault();
          const tabId = this.getAttribute('href').substring(1); // href属性からIDを取得
          showTab(tabId);
        });
      });

      // selectタグの変更イベントリスナー
      tabSelect.addEventListener('change', function handleChange() {
        const tabId = this.value;
        showTab(tabId);
      });

      // 初期表示
      showTab(tabSelect.value);
    }
  });
}
