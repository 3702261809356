/**
 * 垂直方向スクロール位置取得
 *
 * @function
 * @returns {number}
 */
export function getScrollY() {
  return window.scrollY || window.pageYOffset;
}

/**
 * debounce
 *
 * @function
 * @param {Function} func 関数
 * @param {number} [wait=300] 待機時間(ms)
 * @returns {Function}
 */
export function debounce(func, wait = 300) {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

/**
 * debounceLeading
 *
 * @function
 * @param {Function} func 関数
 * @param {number} [wait=300] 待機時間(ms)
 * @returns {Function}
 */
export function debounceLeading(func, wait = 300) {
  let timer;

  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, wait);
  };
}
