import scrollTarget from './scrollTarget';

export default function hashScroll() {
  const { documentElement } = document;

  documentElement.addEventListener('click', (event) => {
    if (
      event.defaultPrevented ||
      event.button !== 0 ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey
    ) {
      return;
    }

    const eventElement = event.target.closest('a[href]:not(.js-modal)');
    const protocolRegexp = /^(https?:|file:\/)\/\//;

    if (eventElement !== null && protocolRegexp.test(eventElement.href)) {
      const currentAnchor = window.location.href.split('#');
      const thisAnchor = eventElement.href.split('#');
      const targetElement = (() => {
        if (currentAnchor[0] === thisAnchor[0] && thisAnchor.length > 1) {
          if (thisAnchor[1]) {
            const htmlElement = document.getElementById(thisAnchor[1]);

            if (htmlElement === null) {
              return thisAnchor[1] === 'top' ? document.body : null;
            }
            return htmlElement;
          }
          return document.body;
        }
        return null;
      })();

      if (
        targetElement === null ||
        eventElement.target === '_blank' ||
        'disableHashScroll' in eventElement.dataset
      ) {
        return;
      }

      scrollTarget(targetElement, 500);
      event.preventDefault();
    }
  });
  window.addEventListener('load', () => {
    const { hash } = window.location;
    const targetElement = hash ? document.getElementById(hash.substring(1)) : null;

    if (targetElement !== null) {
      scrollTarget(targetElement, 500);
      if ('replaceState' in window.history) {
        window.history.replaceState(
          '',
          document.title,
          window.location.pathname + window.location.search,
        );
      }
    }
  });
}
