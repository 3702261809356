export default function toggleMenu() {
  const openTrigger = document.querySelectorAll('.js-menu');
  const closeTriger = document.querySelectorAll('.js-close');
  const toggleClass = () => {
    document.body.classList.toggle('is-open');
  };
  const removeClass = () => {
    document.body.classList.remove('is-open');
  };

  openTrigger.forEach((target) => {
    target.addEventListener('click', toggleClass);
  });

  closeTriger.forEach((target) => {
    target.addEventListener('click', removeClass);
  });
}
