export default function current() {
  // 現在のURLを取得し、アンカーやクエリ文字列を除去
  const { pathname, origin } = window.location;
  let currentPathname = pathname.split('#')[0].split('?')[0];

  // ファイル名（例: index.html, abc.html など）を除去
  const segments = currentPathname.split('/');
  const lastSegment = segments.pop();
  if (lastSegment && lastSegment.includes('.')) {
    currentPathname = segments.join('/');
  } else {
    segments.push(lastSegment);
    currentPathname = segments.join('/');
  }

  // URLの末尾に `/` を追加（必要に応じて）
  if (!currentPathname.endsWith('/')) {
    currentPathname += '/';
  }

  const target = document.querySelectorAll('.js-current');

  for (let i = 0; i < target.length; i += 1) {
    const targetUrl = new URL(target[i].href);
    if (targetUrl.origin === origin) {
      let targetPathname = targetUrl.pathname.split('#')[0].split('?')[0];

      // ファイル名（例: index.html, abc.html など）を除去
      const targetSegments = targetPathname.split('/');
      const targetLastSegment = targetSegments.pop();
      if (targetLastSegment && targetLastSegment.includes('.')) {
        targetPathname = targetSegments.join('/');
      } else {
        targetSegments.push(targetLastSegment);
        targetPathname = targetSegments.join('/');
      }

      if (!targetPathname.endsWith('/')) {
        targetPathname += '/';
      }

      if (targetPathname === currentPathname) {
        target[i].classList.add('is-current');
      }
    }
  }
}

// スクリプトの読み込み後に関数を実行
document.addEventListener('DOMContentLoaded', current);
