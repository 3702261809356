import { getScrollY } from './utility';

export default function inview() {
  const scrollMagic = new ScrollMagic.Controller();
  const setInview = (element) => {
    const dataInviewPosition = element.dataset.inviewPosition;
    const inviewPosition =
      typeof dataInviewPosition === 'undefined' ? 0.9 : Number(dataInviewPosition);

    new ScrollMagic.Scene({
      triggerElement: element,
      triggerHook: inviewPosition,
      reverse: false,
    })
      .setClassToggle(element, '-inview')
      .on('update', (event) => {
        const contentHeight = Math.max(
          document.body.scrollHeight,
          document.body.offsetHeight,
          document.documentElement.clientHeight,
          document.documentElement.scrollHeight,
          document.documentElement.offsetHeight,
        );
        const scrollLimit =
          contentHeight - document.documentElement.clientHeight * (1 - inviewPosition);
        const elementPosition = element.getBoundingClientRect().top + getScrollY();

        event.currentTarget.triggerHook(
          elementPosition >= scrollLimit
            ? (document.documentElement.clientHeight - (contentHeight - elementPosition)) /
                document.documentElement.clientHeight
            : inviewPosition,
        );
      })
      .addTo(scrollMagic);
  };
  document.querySelectorAll('.js-inview').forEach(setInview);
}
