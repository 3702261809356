export default function topSlider() {
  const promiseTargets = {
    'js-inview': '-inview',
    'js-imageloaded': '-imageloaded',
  };
  const promiseTargetsKeys = Object.keys(promiseTargets);

  document.querySelectorAll('.js-topSlider').forEach((element) => {
    const slideNodeList = element.querySelectorAll('.swiper-slide');

    if (slideNodeList.length < 2) return;

    const swiperInstance = new Swiper(element, {
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      speed: 1800,
      observer: true,
      observeParents: true,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      },
    });

    if (swiperInstance.params.autoplay.enabled) {
      swiperInstance.once('slideChange', () => {
        swiperInstance.params.autoplay.delay -= swiperInstance.params.speed;
      });

      if (promiseTargetsKeys.some((className) => element.closest(`.${className}`) !== null)) {
        swiperInstance.autoplay.stop();

        const promiseItems = [];

        promiseTargetsKeys.forEach((className) => {
          promiseItems.push(
            new Promise((resolve) => {
              const targetElement = element.closest(`.${className}`);
              const isDone = () => targetElement.classList.contains(`${promiseTargets[className]}`);

              if (targetElement === null) {
                resolve();
              } else if (isDone()) {
                resolve();
              } else {
                const observer = new MutationObserver(() => {
                  if (isDone()) {
                    observer.disconnect();
                    resolve();
                  }
                });

                observer.observe(targetElement, {
                  attributes: true,
                  attributeFilter: ['class'],
                });
              }
            }),
          );
        });

        Promise.all(promiseItems).then(() => {
          swiperInstance.autoplay.start();
        });
      }
    }
  });
}
