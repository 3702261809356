export default function cssVariable() {
  const { documentElement, body: bodyElement } = document;
  let prevWidth = null;
  let prevHeight = null;

  const resizeHandler = () => {
    const { clientWidth, clientHeight } = documentElement;

    if (clientWidth !== prevWidth) {
      bodyElement.style.setProperty('--jsClientWidth', `${clientWidth}px`);
      prevWidth = clientWidth;
    }
    if (clientHeight !== prevHeight) {
      bodyElement.style.setProperty('--jsClientHeight', `${clientHeight}px`);
      prevHeight = clientHeight;
    }
  };

  window.addEventListener('resize', resizeHandler);
  resizeHandler();
}
